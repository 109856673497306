var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticStyle: { "text-align": "center" },
          attrs: { visible: _vm.dialogVisible, width: "390px" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("p", { staticStyle: { "margin-top": "20px" } }, [
            _vm._v("扫码下载"),
          ]),
          _c("p", { staticStyle: { "line-height": "50px" } }, [
            _vm._v("智慧停车管理端APP"),
          ]),
          _c("vue-qr", {
            staticStyle: { margin: "38px 0" },
            attrs: {
              text: _vm.downloadData,
              logoSrc: require("../../../assets/img/AIpark28.png"),
              size: 234,
              margin: 0,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }