var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { staticClass: "acc-code-login" },
        [
          _c(
            "el-col",
            {
              attrs: { span: 12 },
              nativeOn: {
                click: function ($event) {
                  _vm.isAccOfcode = true
                },
              },
            },
            [
              _c(
                "span",
                { class: _vm.isAccOfcode ? "acc-code-h3" : "acc-code-h4" },
                [_vm._v(_vm._s(_vm.$t("login_page.Account_login")))]
              ),
              _c("span", { class: _vm.isAccOfcode ? "heng" : "" }),
            ]
          ),
          _c(
            "el-col",
            {
              attrs: { span: 12 },
              nativeOn: {
                click: function ($event) {
                  _vm.isAccOfcode = false
                },
              },
            },
            [
              _c(
                "span",
                { class: _vm.isAccOfcode ? "acc-code-h4" : "acc-code-h3" },
                [_vm._v(_vm._s(_vm.$t("login_page.Scan_code_to_log_in")))]
              ),
              _c("span", { class: _vm.isAccOfcode ? "" : "heng" }),
            ]
          ),
        ],
        1
      ),
      !_vm.isAccOfcode
        ? _c("div", { staticClass: "code-login-img" }, [
            !_vm.isLoginSuccess
              ? _c(
                  "div",
                  { staticClass: "code-img" },
                  [
                    !_vm.loginCodeImgSendCk
                      ? _c(
                          "div",
                          { staticClass: "code-invalid" },
                          [
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.$t("login_page.QR_code_invalid"))
                              ),
                            ]),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.getLoginCode },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("login_page.Refresh_QR_code"))
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.loginCodeImg
                      ? _c("vue-qr", {
                          attrs: {
                            text: _vm.loginCodeImg,
                            logoSrc: require("../../assets/img/AIpark28.png"),
                            size: 150,
                            margin: 0,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.isLoginSuccess
              ? _c(
                  "div",
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../assets/img/login/succee.png"),
                        width: "32",
                        height: "32",
                      },
                    }),
                    _c("p", { staticStyle: { "margin-top": "20px" } }, [
                      _vm._v(
                        _vm._s(_vm.$t("login_page.Scan_code_successfully"))
                      ),
                    ]),
                    _c("p", { staticStyle: { "line-height": "50px" } }, [
                      _vm._v(
                        _vm._s(_vm.$t("login_page.confirm_login_on_your_phone"))
                      ),
                    ]),
                    _vm.loginCodeImg
                      ? _c("vue-qr", {
                          attrs: {
                            text: _vm.loginCodeImg,
                            logoSrc: require("../../assets/img/AIpark28.png"),
                            size: 100,
                            margin: 0,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.isLoginSuccess
              ? _c(
                  "p",
                  {
                    staticClass: "code-login-xiazai",
                    on: {
                      click: function ($event) {
                        return _vm.$refs.appDownload.showDialogVisible()
                      },
                    },
                  },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("login_page.open"))),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("login_page.aipark_management_App"))
                      ),
                    ]),
                    _vm._v(
                      _vm._s(_vm.$t("login_page.Scan_code_to_log_in")) + " "
                    ),
                  ]
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _c("app-download", { ref: "appDownload" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }